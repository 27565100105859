.container {
  padding: 0 16px 0 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1116px;
  margin-bottom: 182px;
  align-items: center;
  justify-content: center;
  color: #fdfdfd;
}

.container .congratsMessage h4 {
  font-size: 32px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
}

.container .congratsMessage h4 span {
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container .leftPanel {
  margin-top: 32px;
  margin-bottom: 40px;
}

.leftPanel img {
  max-height: 343px;
  max-width: 343px;
}

.rightPanel h1 {
  margin-top: 0;
  font-size: 32px;
  line-height: 48px;
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 0;
  }

  .container .leftPanel {
    margin-bottom: 48px;
  }

  .leftPanel img {
    max-height: 343px;
    max-width: 343px;
  }

  .rightPanel h1 {
    margin-top: 0;
    font-size: 36px;
    line-height: 54px;
  }

  .container .congratsMessage h4 {
    font-size: 40px;
    line-height: 60px;
  }

  .container .congratsMessage h4 span {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 1023px) {
  .container {
    padding: 0;
  }

  .leftPanel img {
    max-height: 350px;
    max-width: 350px;
  }

  .rightPanel h1 {
    font-size: 36px;
    line-height: 54px;
  }

  .container .congratsMessage h4 {
    margin-top: 100px;
    font-size: 40px;
    line-height: 60px;
  }

  .container .congratsMessage h4 span {
    font-size: 40px;
    line-height: 60px;
  }
}

.noWalletTooltip {
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
}

.noWalletTooltip a {
  display: flex;
  background: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}

.noWalletTooltip a svg {
  display: flex;
  padding-left: 4px;
}

.connectContainer {
  border: 2px solid transparent;
  background-color: #121212;
  border-image: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
  border-image-slice: 1;
}

.connectContainer .connectContainerMessage {
  margin-top: 24px;
  font-family: "Poppins", sans-serif;
}

.connectContainerMessage p {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.connectContainerMessage .web3NoConnectMessage {
  padding: 0 24px 0 24px;
}

.connectContainerMessage .web3ConnectedMessage {
  padding: 0 24px 24px 24px;
}

.connectContainer .connectButton {
  width: 100%;
  padding: 24px;
}

.connectButton button {
  background: linear-gradient(
    90deg,
    rgba(75, 170, 255, 0.3) 0%,
    rgba(46, 179, 219, 0.3) 57%,
    rgba(78, 205, 196, 0.3) 100%
  );
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
  border-image-slice: 1;
  color: #fdfdfd;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-align: center;
  text-transform: unset;
  width: 100%;
  border-radius: 0%;
}

.connectContainer .claimedError {
  display: flex;
  margin-top: 8px;
}

.connectContainer .claimedError span {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.connectContainer p {
  margin: 0;
}

.isConnectingContainer {
  margin-top: 110px;
}

.isConnectingContainer h5 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.isConnectingContainer svg {
  animation: spin 1.2s linear infinite;
}

.connectContainer .walletIdField {
  width: 100%;
  padding-left: 27px;
  padding-right: 27px;
}

.walletIdField input:disabled {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: white;
  background-color: rgba(78, 205, 196, 0.1);
}

.walletIdField label {
  padding-top: 3px;
  padding-left: 39px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #4ecdc4;
}
