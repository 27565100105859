.navigationBar {
  padding-top: 32px;
  padding-left: 16px;
}

@media only screen and (min-width: 768px) {
  .navigationBar {
    padding-left: 24px;
  }
}

@media only screen and (min-width: 1023px) {
  .navigationBar {
    padding-left: 48px;
  }
}

/* betaPhase banner css: */
@media only screen and (max-width: 768px) {
  .betaPhaseBanner {
    background: linear-gradient(
        90deg,
        rgba(75, 170, 255, 0.2) 0%,
        rgba(77, 189, 223, 0.2) 57%,
        rgba(78, 205, 196, 0.2) 100%
      ),
      #121212;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);

    border: 1px solid;
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px;
    gap: 16px;

    width: 100%;
    /* height: 72px; */
    height: 100%;
  }

  .bannerContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    word-break: break-word;
  }

  .contactUsLink {
    cursor: pointer;
    font-weight: 600;
  }

  .bannerClose {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 10.67px;
    height: 10.67px;
  }
}

@media only screen and (min-width: 768px) {
  .betaPhaseBanner {
    background: linear-gradient(
        90deg,
        rgba(75, 170, 255, 0.2) 0%,
        rgba(77, 189, 223, 0.2) 57%,
        rgba(78, 205, 196, 0.2) 100%
      ),
      #121212;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);

    border: 1px solid;
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #4baaff, #4dbddf, #4ecdc4);
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 162px;
    gap: 16px;

    width: 100%;
  }

  .bannerContent {
    padding: 0px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bannerContent .openNewIcon {
    margin-right: 4px;
  }

  .contactUsLink {
    cursor: pointer;
    font-weight: 600;
  }

  .bannerClose {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 10.67px;
    height: 10.67px;
  }
}

.bannerIcon {
  padding-right: 4px;
}

.bannerIcon svg {
  vertical-align: top;
}

.bannerContent .openNewIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
}
