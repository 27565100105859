.container {
  font-family: "Poppins", sans-serif;
  height: 650px;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  color: #fdfdfd;
}

.container p {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin: 0;
}

.container .subtitle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
}

.container .homeButton {
  height: 48px;
  padding: 12px 20px;
}

.container .homeButton a {
  background: linear-gradient(
    90deg,
    rgba(75, 170, 255, 0.85) 0%,
    rgba(46, 179, 219, 0.85) 57%,
    rgba(78, 205, 196, 0.85) 100%
  );
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  color: #fdfdfd;
}
