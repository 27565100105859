.footer .gridContainer {
  display: flex;
  justify-content: flex-end;
  font-family: "Poppins";
  font-size: 16px;
  color: #fdfdfd;
  min-height: 32px;
  padding: 0 16px 0 16px;
  column-gap: 25px;
}

.gridContainer .gridItem {
  display: flex;
  justify-content: flex-end;
  line-height: 32px;
}

.gridItem svg {
  padding-left: 4px;
}

@media only screen and (min-width: 768px) {
  .footer .gridContainer {
    padding-right: 24px;
  }
}

@media only screen and (min-width: 1023px) {
  .footer .gridContainer {
    padding-right: 48px;
    column-gap: 50px;
  }
}
