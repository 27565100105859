.container {
  margin-left: auto !important;
  margin-right: auto !important;
  font-family: "Poppins", sans-serif;
  margin-top: 100px;
  justify-content: center;
  margin-bottom: 100px;
  max-width: 1116px;
}

.container .leftPanel {
  margin-top: 72px;
}

.container .leftPanel,
.container .rightPanel {
  margin-left: 16px;
  margin-right: 16px;
}

.container .rightPanel svg {
  max-width: 100%;
  height: fit-content;
}

.headerTagline {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 24px;
}

.bodyTagline {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: left;
}

.createEventButton {
  padding: 12px 0 12px 0;
  text-align: left;
}

.createEventButton a {
  background: linear-gradient(
    90deg,
    rgba(75, 170, 255, 0.85) 0%,
    rgba(46, 179, 219, 0.85) 57%,
    rgba(78, 205, 196, 0.85) 100%
  );
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  color: #fdfdfd;
  cursor: pointer;
}

.createEventButton a:hover {
  background: linear-gradient(
    90deg,
    rgba(75, 170, 255, 0.5) 0%,
    rgba(46, 179, 219, 0.5) 57%,
    rgba(78, 205, 196, 0.5) 100%
  );
}

@media only screen and (min-width: 768px) {
  .container .leftPanel,
  .container .rightPanel {
    margin-left: 24px;
    margin-right: 24px;
  }
  .container .leftPanel {
    margin-top: 65px;
  }
  .container .rightPanel svg {
    max-width: 514px;
  }
  .headerTagline {
    font-size: 40px;
    line-height: 60px;
  }
  .bodyTagline {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1023px) {
  .container .leftPanel,
  .container .rightPanel {
    margin: 0;
  }
  .container .rightPanel svg {
    max-width: 514px;
  }
  .headerTagline {
    font-size: 40px;
    line-height: 60px;
  }
  .bodyTagline {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 60px;
  }
}
