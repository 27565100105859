.successDisplayContainer {
    text-align: center;
}

.successDisplayContainer h5 {
    margin-top: 12px;
    margin-bottom: 44px;
    font-weight: 700;
    font-size: 18px;
}

.successDisplayContainer a {
    background: linear-gradient(90deg, rgba(75, 170, 255, 0.4) 0%, rgba(46, 179, 219, 0.4) 57%, rgba(78, 205, 196, 0.4) 100%);
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #4BAAFF, #4DBDDF, #4ECDC4);
    border-image-slice: 1;
    border-radius: 4px 4px 0px 0px;
    color: #FDFDFD;
    font-size:16px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-transform: unset;
    text-decoration: none;
    max-width: 287px;
    padding: 12px 20px;
}
